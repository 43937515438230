import React, { useEffect, useMemo, useState } from "react";
import { supabase } from "../utils/supabase";
import BottomNavigation from "../component/bottom-navigation/BottomNavigation";
import OrderModal from "../component/order-modal/OrderModal"
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import "../assets/styles/EnterScore.scss";
import "../assets/styles/_variables.scss";

const EnterScore = () => {
    const history = useNavigate();
    const [players, setPlayers] = useState([]);
    const [game, setGame] = useState();
    const [activeHole, setActiveHole] = useState(1);
    const [scoreData, setScoreData] = useState({});
    const [par, setPar] = useState(0);
    const [modalState, setModalState] = useState([])
    const [endState, setEndState] = useState(false);

    const body = document.body;
    let color = '#A9B892';
    if(body.classList.contains('spooky')){
        color = '#F68A43';
    }
    if(body.classList.contains('christmas')){
        color = '#066F5B';
    }
    if(body.classList.contains('candyland')){
        color = '#DF285F';
    }
 

    const setLocalStorageForhole = (hole) => localStorage.setItem("activeHole", hole);


    useEffect(() => {
        window.scrollTo(0, 0);
        const setThemeColor = () => {
          const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          const themeColor = '#F2F2F2';
          const metaThemeColor = document.querySelector('meta[name="theme-color"]');
          if (metaThemeColor) {
            metaThemeColor.setAttribute('content', themeColor);
          }
        };
        setThemeColor();            
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);
        return () => {
          window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
        };
      }, []);


    const getAllHoledata = async () => {
        let { data: order_modal_settings } = await supabase
        .from('order_modal_settings')
        .select('*');

        const rowData = order_modal_settings.map( (row) => ({ key : row.hole, url : row.url, closed: false}));        
        setModalState(rowData);
    }

    const numberRegex = /^\d+$/;    
    const holesCount = 18;

    const closeDrinkModal = (holeId) => {
        const index = modalState.findIndex(element => element.key == holeId)
        let temp = modalState;
        temp[index] = {key: holeId, closed: true}
        setModalState([...temp])
    }

    const errorAlert = (msg) => {
        Store.addNotification({
            title: "Error!",
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
              click: true,
              showIcon: true,
              pauseOnHover: true
            }
        });
    }

    const getPar = async (holeId) => {

        let { data: gamePar, error } = await supabase
        .from('game_par')
        .select("*")
        .eq('hole', holeId);
        
        setPar(gamePar[0].par)
        return gamePar;
    }
    

    useEffect(() => {
        getPar(activeHole);        
    },[activeHole])


    useEffect(() => {
        const game_data = localStorage.getItem("game_data");
        const player_data = localStorage.getItem("player_data");        
        const agreed = localStorage.getItem("agreed");

        if(game_data === null || game_data.length < 1){
            if(agreed){
                history('/players');
            }
            else{
                history('/');
            }
        }

        if(game_data) {
            setGame(JSON.parse(game_data))
        }

        if(player_data) {
            setPlayers(JSON.parse(player_data))
        }

        getPar(activeHole);

        getAllHoledata();

    },[history])

    const generateEmptyScoreArray = (playersArray) => {
        const playersForScoring = playersArray.map( play => {
            return {
                [play.id] : Array(18).fill(0)
            }
        })
        return playersForScoring.reduce((result, currentObject) => {
            const [key, value] = Object.entries(currentObject)[0];
            result[key] = value;
            return result;
        }, {});        
    }

    useEffect(()=>{
        if(players.length > 0){         
            const transformedObject = generateEmptyScoreArray(players)
            setScoreData(transformedObject)
        }
    },[players])



    const getGameScores = async (thisGame) => {
        let { data: gamesScoresResponse, error } = await supabase
        .from('game_meta')
        .select("*")
        .eq('game_id', thisGame);
        const resolvedPromise = Promise.resolve(gamesScoresResponse)
        const resp = await resolvedPromise.then((value) => {
           return value;
        });
        return resp;
    }

    const findNumberBeforeZero = (arr) => {        
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == 0) {            
            return i;
          } else if (arr[i] !== 0 && arr[i + 1] === 0) {            
            return i;
          }
        } 
      };
    useEffect(() => {

            if(game && game.length > 0) {
                getGameScores(game[0].id).then( data => {
                    let tempObj = generateEmptyScoreArray(players);
                    data.map((d, i) => {
                        if(tempObj[d.player_id]){
                            tempObj[d.player_id][d.hole_number-1] = d.score;
                        }                                        
                    })

                    const toIn = findNumberBeforeZero( tempObj[Object.keys(tempObj)[0]] )
                    //setActiveHole(toIn >= 0 ? toIn+1 : 18);                    
                    setScoreData(tempObj);                
                })
            }            
            setActiveHole(parseInt(localStorage.getItem("activeHole")));

    },[game])

    const doEntry = async (scoreArray) => {

        for(const scoreObj of scoreArray) {
            const { data } = await supabase
            .from('game_meta')
            .update({ score: scoreObj.score })
            .eq('player_id', scoreObj.player_id)
            .eq('game_id', scoreObj.game_id)
            .eq('hole_number', scoreObj.hole_number)
            .select();
            if(data.length === 0)
            {
                const { error } = await supabase
                    .from('game_meta')
                    .insert(scoreObj);
            }
        }
    }
    const debounce = (func, delay) => {
        let timeoutId;      
        return function (...args) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    useEffect(() => {
        let finalScoreb = Object.keys(scoreData).map(key => {
            return {
                player_id: parseInt(key),
                game_id: game[0].id,
                hole_number: activeHole,
                score: scoreData[key][activeHole-1]
            }
        })
        finalScoreb = finalScoreb.filter( scoreitem => scoreitem.score > 0)
        if(finalScoreb.length > 0) {
            doEntry(finalScoreb)
        }
    },[scoreData])

    const increaseScore = (playerId, holeNumber) => {
        setScoreData((prevScoreData) => {
        if (prevScoreData.hasOwnProperty(playerId) && holeNumber-1 >= 0 && holeNumber <= 18) {
            const updatedScoreData = {
            ...prevScoreData,
            [playerId]: prevScoreData[playerId].map((score, index) =>
                index === holeNumber-1 ? score + 1 <= 6 ? score + 1 : score : score
            ),
            };
            return updatedScoreData;
        } else {
            return prevScoreData;
        }
        });
   };

    const decreaseScore = (playerId, holeNumber) => {
        setScoreData((prevScoreData) => {
            if (prevScoreData.hasOwnProperty(playerId) && holeNumber-1 >= 0 && holeNumber <= 18) {
                const updatedScoreData = {
                ...prevScoreData,
                [playerId]: prevScoreData[playerId].map((score, index) =>
                    index === holeNumber-1 ? score - 1 > 0 ? score - 1 : score : score
                ),
                };
                return updatedScoreData;
            } else {
                return prevScoreData;
            }
            });
    }

    const onChangeInput = (playerId, holeNumber, holeInput) => {
      let numberString = holeInput.toString();
      if (numberString.includes('0')) {
        numberString = numberString.replace(/0/g, '');
      }
      
      const value = parseInt(numberString);
      const isItValid = numberRegex.test(numberString);
      
      if(numberString == ''){        
        setScoreData((prevScoreData) => {
            if (prevScoreData.hasOwnProperty(playerId) && holeNumber-1 >= 0 && holeNumber <= 18) {
                const updatedScoreData = {
                ...prevScoreData,
                [playerId]: prevScoreData[playerId].map((score, index) =>
                    index === holeNumber-1 && ''
                ),
                };
                return updatedScoreData;
            } else {
                return prevScoreData;
            }
        });    
    }
     else if(isItValid > 0) {
        setScoreData((prevScoreData) => {
            if (prevScoreData.hasOwnProperty(playerId) && holeNumber-1 >= 0 && holeNumber <= 18) {
                const updatedScoreData = {
                ...prevScoreData,
                [playerId]: prevScoreData[playerId].map((score, index) =>
                    index === holeNumber-1 && value > 0 && value <= 6 ? parseInt(value)  : parseInt(score)
                ),
                };
                return updatedScoreData;
            } else {
                return prevScoreData;
            }
            });
        }
    }

    const progressState = [
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><circle cx="5" cy="5" r="5" fill="#B9B9B9"/></svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><circle cx="7" cy="7" r="7" fill={color}/></svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><circle cx="5" cy="5" r="4.5" fill="white" stroke={color}/></svg>
    ]

    const goToNext = () => {
        if(activeHole < 18 ){
            // const currentHoleScores = Object.keys(scoreData).map( key => scoreData[key][activeHole-1])
            //     if(currentHoleScores.includes(0)){
            //         errorAlert( "Players missing score for hole "+activeHole)   
            // } else {
                setActiveHole(prevState => prevState+1);
                setLocalStorageForhole(activeHole+1);
            //}
        }
    }

    const goToPrev = () => {
        if(activeHole > 1){
            setActiveHole(prevState => prevState-1)
            setLocalStorageForhole(activeHole-1);
        }
        else{
            errorAlert( "No more previous hole")
        }
    }
    
    const getThisHoleState = useMemo(()=>{
        const drinkState = modalState.find(element => element.key == activeHole)
        let thisHoleState = true;

        if(drinkState && drinkState.closed === true){ thisHoleState = false; }

        return thisHoleState;
    },[modalState,activeHole])


    const endGame = async () => {
        const game_data = localStorage.getItem("game_data");
        if(game_data){
            
            const { data } = await supabase
            .from('games')
            .update({ status: 'completed' })
            .eq('id', JSON.parse(game_data)[0].id)
            .select()
            if(data){
               history("/result")
            }
        }
    }


    return <div className="VP-enter-score">
        {
            modalState.findIndex(index => index.key == activeHole) > -1 && 
            getThisHoleState && 
            <OrderModal data={modalState.filter(state=> state.key == activeHole)} onClose={() => closeDrinkModal(activeHole)}/>
        }
        <div className="enter-score-content">
            <h2>Add Your Score</h2>
            <div className="progress_holder">  
                {
                    Array.from({ length: holesCount }, (_, index) => index).map(index => <span key={index+"span"}>
                        {
                        activeHole-1 === index ? progressState[1] : activeHole-2 >= index ? progressState[0] : progressState[2]
                        }
                    </span>)
                }
            </div>
            <h1>Hole {activeHole}</h1>
            <h3>PAR {par}</h3>
            {
                players.map( player => {
                const gamePlayerData = scoreData[player.id] ? scoreData[player.id][activeHole-1] : 0;                
                return <div key={player.id+"_player"} className="user_and_score_holder">
                        <div className="username"><h2>{player.name}</h2></div>
                        <div className="userscore">
                            <span onClick={()=> debounce(increaseScore(player.id, activeHole), 300) }>+</span>
                            <input type="number" step="1"  min={1} max={6} onChange={e => debounce( onChangeInput(player.id, activeHole, e.target.value), 300) } value={gamePlayerData > 0 ? gamePlayerData : ''}/>
                            <span className="minus_span" onClick={()=> debounce(decreaseScore(player.id, activeHole), 300) }>-</span>
                        </div>
                </div>})
            }

            

            <div className="score-navigation">
                <span onClick={ ()=> goToPrev()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                    <path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="#5D5D5D"/>
                </svg> PREV
                </span>
                <span>
                    <a href="/view-score">VIEW SCORECARD</a>
                </span>
                {
                    activeHole < 18 ?  <span onClick={ ()=> goToNext()}>
                    NEXT <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464466C7.97631 0.269204 7.65973 0.269204 7.46447 0.464466C7.2692 0.659728 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5H11V3.5H1V4.5Z" fill="#5D5D5D"/>
                    </svg>
                </span> : <span onClick={() => setEndState(!endState)} >END GAME</span>
                }
            </div>

            <p className="bold_text">Drinks and Snacks Delivered</p>
            <p className="small_text">Scan the QR Code at the start of your next hole to have drinks & snacks delivered!</p>
            {
                !([15,16,17,18].includes(activeHole)) && activeHole !== 18 && <a href={`https://order.micropower.com.au/victoparkgolfcompvicprosh?location=Hole%20${activeHole+2}`} className="anchorBtn" target="_blank">ORDER DRINKS OR SNACKS</a>
            }           

            
        </div>
            {
                endState && <div className="endGameModal_container">
                <div className="endGameModal">
                    <h3>Ready to END your GAME?</h3>
                    <p>This action cannot be undone.<br/>Once confirmed, the final scores will be displayed,and the game will conclude.</p>
                    <button className="btnColored" onClick={()=>  endGame()}>I AGREE</button><br/>
                    <button className="btnNormal" onClick={() => setEndState(!endState)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="#5D5D5D"/>
                        </svg>&nbsp;
                        Continue playing
                    </button>
                </div>
            </div>
            }  
        <BottomNavigation/>
    </div>
}
export default EnterScore
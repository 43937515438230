import React , {useState, useEffect} from "react";
import DashboardComponent from "../../component/dashboard/Dashboard";
import { supabase } from "../../utils/supabase";
import { Store } from 'react-notifications-component'
import "../../assets/styles/HomePageSettings.scss";
import Primary from "../../assets/images/themes/default.png"
import Spooky from "../../assets/images/themes/spooky.png"
import Christmas from "../../assets/images/themes/christmas.png"
import Candyland from "../../assets/images/themes/candyland.png"

export default function ThemeSelection(){
    const [theme, setTheme] = useState();
    const [currImage, setCurrImage] = useState('');

    const [file, setFile] = useState();
 
    const getSavedTheme = async () => {
        const { data } = await supabase
        .from('theme')        
        .select()
        .eq("status", true);        
        if(data[0]?.theme){
            setTheme(data[0]);
        }
    }


    const getFlePublicUrl = async () => {
        if(theme?.background){
            
            const { data } = await supabase
                .storage
                .from('vpscorecard')
                .getPublicUrl( theme.background );

            if(data && data.publicUrl){              
                setCurrImage(data?.publicUrl)
            }

            
        }
        
    }


    useEffect(()=>{
        getSavedTheme()
    },[])

    useEffect(()=>{        
        getFlePublicUrl()
        // eslint-disable-next-line
    },[theme]) 

    const saveTheme = async () => { 

             //set previous active theme to false
             await supabase
               .from('theme')
               .update({ status: false })
               .eq('status', 'true')
               .select();
        

            const { data:newTheme } = await supabase
            .from('theme')
            .update({ status: true  })
            .eq('theme', theme.theme)
            .select()

            if(file?.name){
                const { data:imageData} = await supabase
                .storage
                .from('vpscorecard')
                .upload(file.name, file, {
                    cacheControl: '0',
                    upsert: true
                })                

                const {data:afterImageUpdate} = await supabase
                    .from('theme')
                    .update({ background: imageData.path  })
                    .eq('theme', theme.theme)
                    .select()
                    setTheme(afterImageUpdate[0])
            }
            
            
            if(newTheme){
                Store.addNotification({
                    title: "Success!",
                    message: 'Theme Updated successfully',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true,
                        click: true,
                        showIcon: true,
                        pauseOnHover: true
                    }
                });
            }
            
    }

    return <DashboardComponent>
            <div className="container_home_settings">
                <h3>Theme Selection</h3>
                 
                <table>
                    <tbody>
                        <tr>
                            <td>Select Theme: </td>
                            <td> 
                                <select value={theme?.theme} onChange={(e)=>setTheme(prev => ({...prev, theme: e.target.value}))}>
                                    <option value="default">Default</option>
                                    <option value="spooky">Spooky</option>
                                    <option value="christmas">Christmas</option>
                                    <option value="candyland">Candyland</option>
                                </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {/* <tr>
                            <td>Select Background Image: </td>
                            <td>
                                <input type="file" placeholder="Home page background" onChange={(file)=>setFile(file.target.files[0])} accept="image/*" />
                            </td>
                            <td></td>
                            <td>Current Active Image:</td>
                            <td>{currImage &&  <img width={20} src={currImage} alt="logo"/>}</td>
                        </tr> */}
                    </tbody>
                </table>
                <br/><br/>
                <input type="button" value={"save"} onClick={()=>saveTheme()}/>
                <br/><br/>
                <h2>Example Theme Images</h2>
                <p style={{fontSize: "12px"}}>Right click and select option to save</p><br/>
                <div style={{display: "flex", gap: "15px"}}>                    
                    <img src={Primary} height="400px" alt="Primary"/>
                    <img src={Spooky} height="400px" alt="Spooky"/>
                    <img src={Christmas} height="400px" alt="Christmas"/>
                    <img src={Candyland} height="400px" alt="Candyland"/>
                </div>

                
            </div>            
        </DashboardComponent>
}
import React, { useEffect, useState } from 'react';
import logo from '../assets/images/Victoria-Park-Logo.png';
import '../assets/styles/App.scss';
import { supabase } from "../utils/supabase";
import Primary from "../assets/images/themes/default.png"
import spooky from "../assets/images/themes/spooky.png"
import christmas from "../assets/images/themes/christmas.png"
import candyland from "../assets/images/themes/candyland.png"


function App() {

  const [fileUrl, setFileUrl] = useState('');
  const [defaultTheme, setDefaultTheme] = useState(true);

  const context = { spooky: spooky,  christmas: christmas, candyland: candyland }; 
  
  const getFlePublicUrl = async () => {

    const { data:theme} = await supabase
        .from('theme')        
        .select()
        .eq("status", true); 
        
        setFileUrl(theme[0]?.theme);
  
     if(theme[0]?.background ){   
        if(theme[0]?.theme !== "default"){
          setDefaultTheme(prev => false);           
        }        
        
        // let { data:image } = await supabase
        //                 .storage
        //                 .from('vpscorecard')
        //                 .getPublicUrl(theme[0]?.background)
    
        // if(image && image.publicUrl){
        //   setFileUrl(image.publicUrl)
          
        // }
     }

    
  } 

  const setThemeColor = async () => {
    const body = document.body;
    let themeColor = '#A9B892';
    if(body.classList.contains('spooky')){
      themeColor = '#453460';        
    }
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', themeColor);
    }
  };


  useEffect(()=>{
    
    localStorage.setItem("game_data", '');
    localStorage.setItem("player_data", '');   
    localStorage.setItem("agreed", false);

    getFlePublicUrl();
    //setThemeColor()

  }) 


  useEffect(() => {
    // const setThemeColor = () => {
    //   const body = document.body;
    //   let themeColor = '#A9B892';
    //   if(body.classList.contains('spooky')){
    //     themeColor = '#453460';        
    //   }
    //   const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    //   if (metaThemeColor) {
    //     metaThemeColor.setAttribute('content', themeColor);
    //   }
    // };

    // // Call the function initially
     setThemeColor();

    // // Add event listener for changes in color scheme preference
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);

    // // Clean up function
    // return () => {
    //   window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
    // };
  }, []); // Empty dependency array to ensure effect runs only once

  useEffect(() => {
    const handleClassChange = () => {
      const body = document.body;            
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (body.classList.contains('spooky')) {             
        metaThemeColor.setAttribute('content', '#453460');        
      } else {
          metaThemeColor.setAttribute('content', '#A9B892');        
      }
    };

    // MutationObserver to watch for changes in body class attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          handleClassChange(); // Call your handler function when class changes
        }
      });
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  

  const body = document.body;   
  return (
    <div className="VP-root" style={{background:`url(${fileUrl === "default" ? Primary : context[fileUrl]})`}}>
          <div className="VP-root-logo-holder">{
            body.classList.contains('candyland') ? <svg xmlns="http://www.w3.org/2000/svg" width="150" height="16" viewBox="0 0 150 16" fill="none">
            <g clip-path="url(#clip0_576_2140)">
              <path d="M9.55577 14.1667L1.70202 0H0L8.42109 15.1C8.6992 15.6222 8.81044 15.7667 9.1108 15.9111C9.24429 15.9667 9.37778 15.9889 9.5224 15.9889C9.82275 15.9889 10.112 15.8778 10.3233 15.6667L10.3678 15.6111C10.4012 15.5556 10.4568 15.4778 10.5347 15.3556L19.0893 0H17.3873L9.55577 14.1667Z" fill="black"/>
              <path d="M21.5366 1.57779C21.1362 1.31112 20.4798 1.34446 20.135 1.62223C19.9681 1.74446 19.8346 2.02223 19.8346 2.27779C19.8346 2.51112 19.9236 2.71112 20.1016 2.85557C20.3018 3.02223 20.5911 3.11112 20.8914 3.11112C21.1695 3.11112 21.4254 3.03335 21.6145 2.87779C21.8481 2.71112 21.9705 2.47779 21.9594 2.22223C21.9371 1.96668 21.7814 1.73335 21.5366 1.57779Z" fill="black"/>
              <path d="M21.6034 3.76666H20.1795V15.8556H21.6034V3.76666Z" fill="black"/>
              <path d="M26.1198 14.2C25.3745 13.7444 25.063 13.2111 25.063 12.3556V7.06667C25.063 6.03333 25.3078 5.65555 26.2756 5.23333C26.6761 5.05555 27.1878 4.93333 28.367 4.93333H34.7745V3.75555H28.2223C25.1854 3.75555 23.6503 4.91111 23.6503 7.17778V12.4333C23.6503 13.9889 24.3845 14.9444 26.0309 15.5333C26.7206 15.7667 27.3769 15.8444 28.7118 15.8444H34.8747V14.6889H28.7674C27.2212 14.7 26.7206 14.5444 26.1198 14.2Z" fill="black"/>
              <path d="M38.6013 0H37.1774V15.8556H38.6013V4.93333H43.2179V3.76667H38.6013V0Z" fill="black"/>
              <path d="M56.567 7.2V12.6222C56.567 13.6333 56.1443 14.1667 55.0541 14.5222C54.6425 14.6556 54.3311 14.7 53.2075 14.7H49.3696C48.1682 14.7 47.8567 14.6556 47.4006 14.5222C46.1992 14.1444 45.7654 13.6333 45.7654 12.5667V7.2C45.7654 6.07778 46.2103 5.51111 47.4006 5.11111C47.8234 4.95555 48.1015 4.93333 49.3585 4.93333H53.1964C54.598 4.93333 54.9985 5.01111 55.6215 5.38889C56.3446 5.82222 56.567 6.24444 56.567 7.2ZM53.2631 3.75555H49.3363C47.5564 3.75555 46.8889 3.88889 45.9545 4.44444C44.7308 5.13333 44.3414 5.85555 44.3414 7.4V12.3222C44.1078 13.7778 45.0534 15 46.8667 15.5889C47.5007 15.7889 47.9346 15.8333 49.3363 15.8333H53.2631C54.8873 15.8333 55.5436 15.7 56.4669 15.2C57.6127 14.5222 58.0243 13.7778 58.0243 12.3556V7.41111C58.0243 4.95555 56.4669 3.75555 53.2631 3.75555Z" fill="black"/>
              <path d="M64.4987 3.76667C64.3985 3.77778 64.3096 3.77778 64.2094 3.77778C61.3616 3.97778 60.1046 5.05555 60.1046 7.27778L60.0712 15.8444H61.5285V7.28889C61.5285 5.8 62.3628 5.07778 64.2651 4.95555C64.3985 4.93333 64.5209 4.93333 64.6544 4.93333H66.1451V3.75555H64.799C64.6989 3.75555 64.5988 3.76667 64.4987 3.76667Z" fill="black"/>
              <path d="M69.6603 1.57777C69.2599 1.32221 68.6035 1.35555 68.2587 1.62221C68.0918 1.74444 67.9583 2.02221 67.9583 2.27777C67.9583 2.5111 68.0473 2.7111 68.2253 2.84444C68.4367 3.0111 68.7148 3.09999 69.0151 3.09999C69.2932 3.09999 69.5491 3.02221 69.7382 2.86666C69.9607 2.7111 70.0831 2.47777 70.0831 2.23333C70.0719 1.96666 69.9162 1.73333 69.6603 1.57777Z" fill="black"/>
              <path d="M69.7382 3.76666H68.3143V15.8556H69.7382V3.76666Z" fill="black"/>
              <path d="M81.4521 14.7H76.4462C76.2793 14.7 76.1124 14.6778 75.9678 14.6556C73.654 14.3778 73.3536 13.4111 73.3536 12.6V10.8889C73.3536 9.4 74.7108 8.94444 75.8455 8.81111C76.0123 8.78889 76.1903 8.76667 76.3572 8.76667H84.3555V12.6333C84.3555 14.4333 82.5423 14.7 81.4521 14.7ZM80.64 3.75555H71.9631V4.93333H81.1184C83.1986 4.93333 84.3889 5.72222 84.3889 7.11111V7.62222H76.4239C73.6985 7.62222 71.9408 8.91111 71.9408 10.9111V12.6444C71.9742 14.3556 73.387 15.5444 75.7231 15.8C76.0012 15.8222 76.2793 15.8444 76.524 15.8444H81.4298C82.9094 15.8444 83.9439 15.5444 84.7894 14.8667C85.557 14.2222 85.8017 13.6444 85.8017 12.4667V7.24444C85.8128 6.32222 85.5347 5.56667 84.9896 5.01111C84.1442 4.16667 82.7203 3.75555 80.64 3.75555Z" fill="black"/>
              <path d="M106.66 8.5H97.4266V1.23333H106.66C108.796 1.23333 110.353 2.14444 110.353 3.38889V5.82222C110.353 5.96667 110.353 6.12222 110.331 6.24444C110.019 7.83333 108.918 8.5 106.66 8.5ZM110.542 1.03333C109.585 0.3 108.451 0 106.626 0H95.8803V15.8556H97.4266V9.74444H106.504C109.919 9.74444 111.888 8.32222 111.888 5.85556V3.18889C111.888 2.65556 111.599 2.04444 111.109 1.52222C110.954 1.32222 110.753 1.15556 110.542 1.03333Z" fill="black"/>
              <path d="M122.712 14.7H117.706C117.539 14.7 117.372 14.6778 117.228 14.6556C114.914 14.3778 114.625 13.4111 114.625 12.6V10.8889C114.625 9.4 115.982 8.94444 117.117 8.81111C117.283 8.78889 117.461 8.76667 117.628 8.76667H125.627V12.6333C125.616 14.4333 123.791 14.7 122.712 14.7ZM121.9 3.75555H113.223V4.93333H122.378C124.459 4.93333 125.649 5.72222 125.649 7.11111V7.62222H117.673C114.947 7.62222 113.19 8.91111 113.19 10.9111V12.6444C113.223 14.3556 114.636 15.5444 116.972 15.8C117.25 15.8222 117.528 15.8444 117.773 15.8444H122.679C124.158 15.8444 125.182 15.5444 126.027 14.8667C126.795 14.2222 127.039 13.6444 127.039 12.4667V7.24444C127.051 6.32222 126.772 5.56667 126.227 5.01111C125.404 4.16667 123.98 3.75555 121.9 3.75555Z" fill="black"/>
              <path d="M133.225 3.76667C133.136 3.77778 133.035 3.77778 132.935 3.77778C130.088 3.97778 128.83 5.05555 128.83 7.27778L128.797 15.8444H130.254V7.28889C130.254 5.8 131.089 5.07778 132.98 4.95555C133.113 4.93333 133.236 4.93333 133.369 4.93333H134.86V3.75555H133.514C133.425 3.75555 133.325 3.76667 133.225 3.76667Z" fill="black"/>
              <path d="M140.355 9.75555L149.833 3.75555H147.553L139.499 8.83333C138.931 9.17778 138.653 9.5 138.653 9.82222C138.653 9.97778 138.709 10.2 139.009 10.3778L147.642 15.8444H150.011L140.355 9.75555Z" fill="black"/>
              <path d="M137.897 0H136.495V15.8556H137.897V0Z" fill="black"/>
            </g>
            <defs>
              <clipPath id="clip0_576_2140">
                <rect width="150" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg> :  <img src={logo} className="VP-root-logo" alt="victoria-park"/>
            }
            
          </div>

          {/* {defaultTheme && <h1>
            PUTT PUTT SCORECARD
          </h1>} */}
          <h1>
            PUTT PUTT SCORECARD
          </h1>
          <div className="VP-root-button-holder">
            <div>
              <p className="home-note-head">Please note:</p>
              <p className="home-note-subhead">Don’t forget to choose your putter at the start of Hole 1!</p>
            </div>
            <a href="/rules" >LET'S PLAY</a>
          </div>
    </div>
  );
}

export default App;

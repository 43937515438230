import React , {useState, useEffect} from "react";
import DashboardComponent from "../../component/dashboard/Dashboard";
import { supabase } from "../../utils/supabase";
//import { Store } from 'react-notifications-component'
import gameSvg from "../../assets/images/games.svg";
import players from "../../assets/images/players.svg";
import "../../assets/styles/HomePageSettings.scss";

export default function HomePageSettings(){
    // const [file, setFile] = useState();
    //const [currentFile, setCurrentFile] = useState('');
    const [filesToRemove, setFilesToRemove] = useState([]);
    console.log(filesToRemove);
    const [totalGamesCompleted, setGamesCompleted] = useState(0);
    const [todayStates, setTodayStates] = useState({
        completed:0,
        inProgress:0,
        totalPlayers:0,
        lastMonthCompleted: 0,  
        thisMonthCompleted: 0,
        lastLastMonthCompleted: 0      
    })


    const getTotalGamesPLayed = async () => {
        const {count} = await supabase.from('games').select('*', { count: 'exact', head: true }).eq("status", "completed");
        setGamesCompleted(count);
    }

    const getTotalplayers = async () => {
        const {count} = await supabase.from('players').select('*', { count: 'exact', head: true });
        setTodayStates(prev => ({...prev, totalPlayers: count }))
    }

    const geteTodayGamesStates = async () => {
        const today = new Date();
        const startOfDay = today.toISOString().split('T')[0] + 'T00:00:00.000Z'; // Start of today in UTC
        const endOfDay = today.toISOString().split('T')[0] + 'T23:59:59.999Z'; // End of today in UTC

        const { count } = await supabase
        .from('games')
        .select('*', { count: 'exact', head: true }) // Only fetch count
        .gte('created_at', startOfDay)
        .lt('created_at', endOfDay)
        .eq("status", "completed");
        setTodayStates(prev => ({...prev, completed: count }))
    }
    const geteTodayGamesStatesInProgress = async () => {
        const today = new Date();
        const startOfDay = today.toISOString().split('T')[0] + 'T00:00:00.000Z'; // Start of today in UTC
        const endOfDay = today.toISOString().split('T')[0] + 'T23:59:59.999Z'; // End of today in UTC

        const { count } = await supabase
        .from('games')
        .select('*', { count: 'exact', head: true }) // Only fetch count
        .gte('created_at', startOfDay)
        .lt('created_at', endOfDay)
        .eq("status", "inProgress");
        setTodayStates(prev => ({...prev, inProgress: count }))
    }
    const getLastLastMonthCompleted = async () => {
        const today = new Date();
        //console.log(new Date(new Date().getUTCMonth()-2 > -1 ? new Date().getUTCMonth()-2 : `12-14-${today.getUTCFullYear()-1}`))
        // Get the first day of the last month (UTC)
        const firstDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth() - 2, 1);
        const startOfLastMonth = firstDayLastMonth.toISOString().split('T')[0] + 'T00:00:00.000Z';

        // Get the last day of the last month (UTC)
        const lastDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth()-1, 0);
        const endOfLastMonth = lastDayLastMonth.toISOString().split('T')[0] + 'T23:59:59.999Z';        
        
        const { count } = await supabase
        .from('games')
        .select('*', { count: 'exact', head: true }) // Only fetch count
        .gte('created_at', startOfLastMonth)
        .lt('created_at', endOfLastMonth)
        .eq("status", "inProgress");
        setTodayStates(prev => ({...prev, lastLastMonthCompleted: count }))
    }
    const getLastMonthCompleted = async () => {
        const today = new Date();
  
        // Get the first day of the last month (UTC)
        const firstDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth() - 1, 1);
        const startOfLastMonth = firstDayLastMonth.toISOString().split('T')[0] + 'T00:00:00.000Z';

        // Get the last day of the last month (UTC)
        const lastDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth(), 0);
        const endOfLastMonth = lastDayLastMonth.toISOString().split('T')[0] + 'T23:59:59.999Z';        

        const { count } = await supabase
        .from('games')
        .select('*', { count: 'exact', head: true }) // Only fetch count
        .gte('created_at', startOfLastMonth)
        .lt('created_at', endOfLastMonth)
        .eq("status", "inProgress");
        setTodayStates(prev => ({...prev, lastMonthCompleted: count }))
    }

    const getThisMonthCompleted = async () => {
        const today = new Date();
  
        // Get the first day of the last month (UTC)
        const firstDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth(), 1);
        const startOfLastMonth = firstDayLastMonth.toISOString().split('T')[0] + 'T00:00:00.000Z';

        // Get the last day of the last month (UTC)
        const lastDayLastMonth = new Date(today.getUTCFullYear(), today.getUTCMonth()+1, 0);
        const endOfLastMonth = lastDayLastMonth.toISOString().split('T')[0] + 'T23:59:59.999Z';        

        const { count } = await supabase
        .from('games')
        .select('*', { count: 'exact', head: true }) // Only fetch count
        .gte('created_at', startOfLastMonth)
        .lt('created_at', endOfLastMonth)
        .eq("status", "inProgress");
        setTodayStates(prev => ({...prev, thisMonthCompleted: count }))
    }

    // const getFlePublicUrl = async () => {

    //     const { data } = await supabase
    //             .storage
    //             .from('vpscorecard')
    //             .list('', {
    //                 limit: 100,
    //                 offset: 0,
    //                 sortBy: { column: 'name', order: 'asc' },
    //             })
                
    //      const file = data;
    //      if(file.length > 0){
    //         let { data } = await supabase
    //                         .storage
    //                         .from('vpscorecard')
    //                         .getPublicUrl(file[0].name)
        
    //         if(data && data.publicUrl){
    //             setCurrentFile(data.publicUrl)
    //         }
    //      }

        
    //   }
    const getBucketDetails = async () => {
        const { data } = await supabase
                .storage
                .from('vpscorecard')
                .list('', {
                    limit: 100,
                    offset: 0,
                    sortBy: { column: 'name', order: 'asc' },
                })
         if(data.length > 0){
            const filesToRemove = [];
            data.map( file => filesToRemove.push(file.name))
            setFilesToRemove(filesToRemove);
         }
    }

    useEffect(()=>{
        //getFlePublicUrl();
        getBucketDetails();
        getTotalGamesPLayed();
        geteTodayGamesStates();
        geteTodayGamesStatesInProgress();
        getTotalplayers();
        getLastMonthCompleted();
        getThisMonthCompleted();
        getLastLastMonthCompleted();
    },[])

    // const saveFile = async () => {

    //     if(file){


    //         await supabase
    //         .storage
    //         .from('vpscorecard')
    //         .remove(filesToRemove)
           
    //         const { data, error } = await supabase
    //             .storage
    //             .from('vpscorecard')
    //             .upload(file.name, file, {
    //                 cacheControl: '0',
    //                 upsert: true
    //             })
    //             if(data){
    //                 Store.addNotification({
    //                     title: "Success",
    //                     message: "Image uploaded successfully",
    //                     type: "success",
    //                     insert: "top",
    //                     container: "top-right",
    //                     animationIn: ["animate__animated", "animate__fadeIn"],
    //                     animationOut: ["animate__animated", "animate__fadeOut"],
    //                     dismiss: {
    //                       duration: 4000,
    //                       onScreen: true,
    //                       click: true,
    //                       showIcon: true,
    //                       pauseOnHover: true
    //                     }
    //                 })
    //                 getFlePublicUrl();
    //                 getBucketDetails();
    //             }
 

    //             if(error){
    //                 Store.addNotification({
    //                     title: "Error!",
    //                     message: "An error occured, Please try again!",
    //                     type: "danger",
    //                     insert: "top",
    //                     container: "top-right",
    //                     animationIn: ["animate__animated", "animate__fadeIn"],
    //                     animationOut: ["animate__animated", "animate__fadeOut"],
    //                     dismiss: {
    //                       duration: 4000,
    //                       onScreen: true,
    //                       click: true,
    //                       showIcon: true,
    //                       pauseOnHover: true
    //                     }
    //                 });
    //             }
    //     } else {
    //         Store.addNotification({
    //             title: "Error!",
    //             message: "No image selected",
    //             type: "danger",
    //             insert: "top",
    //             container: "top-right",
    //             animationIn: ["animate__animated", "animate__fadeIn"],
    //             animationOut: ["animate__animated", "animate__fadeOut"],
    //             dismiss: {
    //               duration: 4000,
    //               onScreen: true,
    //               click: true,
    //               showIcon: true,
    //               pauseOnHover: true
    //             }
    //         });
    //     }
    // }

    return <DashboardComponent>
            <div className="container_home_settings">
                {/* <h3>App states:</h3> */}

                <div className="card_container">
                    <div className="card">
                        <h2 className="title">Total Games Completed</h2>                        
                        <div className="inner-flex">
                            <img src={gameSvg} alt="gameSvg"/>
                            <p className="value">{totalGamesCompleted}</p>
                        </div>
                    </div>
                    <div className="card">
                        <h2 className="title">Total Players</h2>                        
                        <div className="inner-flex">
                            <img src={players} alt="players"/>
                            <p className="value">{todayStates.totalPlayers}</p>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
                <h3>Today:</h3>
                <div className="card_container">

                    <div className="card">
                        <h2 className="title">Games Completed</h2>
                        <div className="inner-flex">
                            <img src={gameSvg} alt="gameSvg"/>
                            <p className="value">{todayStates.completed}</p>
                        </div>
                    </div>
                    <div className="card">
                        <h2 className="title">Games In-Progress</h2>
                        <div className="inner-flex">
                            <img src={gameSvg} alt="svggame"/>
                            <p className="value">{todayStates.inProgress}</p>
                        </div>
                    </div>
                </div>
                <br/>
                <h3>Completed Games (per month):</h3>
                <div className="card_container">
                    <div className="card">                        
                        <h2 className="title">{new Date(new Date().getUTCMonth()-2 > -1 ? new Date().getUTCMonth()-2 : `12-14-${new Date().getUTCFullYear()-1}`).toLocaleString('en-US', { month: 'long' })} {new Date().getUTCFullYear()}</h2>
                        <div className="inner-flex">
                            <img src={gameSvg} alt="fcom"/>
                            <p className="value">{todayStates.lastLastMonthCompleted}</p>
                        </div>
                    </div>
                    <div className="card">                        
                        <h2 className="title">{new Date(`${new Date().getUTCMonth()}-01-${new Date().getUTCFullYear()}`).toLocaleString('en-US', { month: 'long' })} {new Date().getUTCFullYear()} </h2>
                        <div className="inner-flex">
                            <img src={gameSvg} alt="Scom"/>
                            <p className="value">{todayStates.lastMonthCompleted}</p>
                        </div>
                    </div>
                    <div className="card">
                        <h2 className="title">{new Date().toLocaleString('en-US', { month: 'long' })} {new Date().getUTCFullYear()}</h2>
                        <div className="inner-flex">
                            <img src={gameSvg} alt="Tcom"/>
                            <p className="value">{todayStates.thisMonthCompleted}</p>
                        </div>
                    </div>
                </div>
                {/*<label className="form-label">Background image</label>
                <input type="file" placeholder="Home page background" onChange={(file)=>setFile(file.target.files[0])} accept="image/*" />

                <input type="button" value={"save"} onClick={()=>saveFile()}/>
                <br/><br/>
                <label className="form-label">Current image</label>
                <img src={currentFile}  style={{width:"200px"}}/>
                */}
            </div>            
        </DashboardComponent>
}